import type { Config } from '@measured/puck';
import { CategoryEventsBlock } from './blocks/category-events';
import { CategoryAllEventsBlock } from './blocks/category-all-events';
import { HeadingBlock } from './blocks/header';
import { NavigationMenu } from './blocks/navigation-menu';
import { EventBannerBlock } from './blocks/event-banner';
import { EventContentBlock } from './blocks/event-content';
import { TextContentBlock } from './blocks/text-content';
import { SearchBlock } from './blocks/search';
import { Footer } from './blocks/footer';

type RootProps = {
  title: string;
  description: string;
  visible: boolean;
  favicon: string;
};

type ContentType = any[];

type RootType = {
  props: RootProps;
};

type ZonesType = object;

export type PageTemplateType = {
  content: ContentType;
  root: RootType;
  zones: ZonesType;
};

export const newPageTemplate: PageTemplateType = {
  content: [],
  root: { props: { title: 'New page', visible: false, description: '', favicon: '' } },
  zones: {},
};

// TODO: Fix any type
export const getConfigWithData = (accountSlug: string, eventData?: any): Config => {
  const config: Config = {
    root: {
      fields: {
        title: { type: 'text', label: 'Page title' },
        description: { type: 'textarea', label: 'Description (for SEO)' },
        visible: {
          label: 'Visibility',
          type: 'radio',
          options: [
            { label: 'Public', value: true },
            { label: 'Hidden', value: false },
          ],
        },
        favicon: { type: 'text', label: 'Favicon URL' },
      },
      defaultProps: {
        title: 'New page',
        description: '',
        visible: false,
        favicon: '',
      },
    },
    components: {
      HeadingBlock: HeadingBlock(accountSlug),
      NavigationMenu: NavigationMenu(accountSlug),
      CategoryEvents: CategoryEventsBlock(accountSlug),
      CategoryAllEvents: CategoryAllEventsBlock(accountSlug),
      EventBanner: EventBannerBlock(accountSlug),
      EventContent: EventContentBlock(accountSlug, eventData),
      TextContent: TextContentBlock(accountSlug),
      Search: SearchBlock(accountSlug),
      Footer: Footer(accountSlug),
    },
  };

  return config;
};
